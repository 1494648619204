import { CATALOGS, SIGN_OUT } from '../actions/constants';

const initialState = {};

export default function catalogs(state = initialState, action) {
  const { data } = action;

  switch (action.type) {
    case CATALOGS.handlers.get:
      return {
        ...state,
        handling: true,
      };
    case CATALOGS.update:
      return {
        data,
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
