import { Heading, Pane } from 'evergreen-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { Element } from 'react-scroll';

import Product from './Product';

function Products() {
  const products = useSelector(({ catalogs }) => catalogs.data?.products ?? {});

  return (
    <Pane background="rgba(235,237,240,0.8)">
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={1024}
        padding={40}
        margin="auto"
      >
        <Element name="products">
          <Heading size={800} textAlign="center">
            Hệ thống sản phẩm
          </Heading>
          <Heading textAlign="center" size={400} marginTop={10} marginBottom={20}>
            Tất cả các ứng dụng đều được tích hợp toàn diện với nhau, dễ dàng sử dụng trên trình
            duyệt và thiết bị di động
          </Heading>
          <Pane display="block">
            {Object.entries(products).map(([id, product], idx) => (
              <Product key={id} id={id} {...product} index={idx} />
            ))}
          </Pane>
        </Element>
      </Pane>
    </Pane>
  );
}

export default Products;
