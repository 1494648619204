import { Pane, Spinner } from 'evergreen-ui';
import React from 'react';

function Waiting() {
  return (
    <Pane
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      backgroundColor="rgba(16, 16, 16, 0.2)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={9999}
    >
      <Spinner />
    </Pane>
  );
}

export default Waiting;
