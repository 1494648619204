import { Heading, IconButton, Menu, MenuIcon, Pane, Popover } from 'evergreen-ui';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link as ScrollLink } from 'react-scroll';

import { RESOURCES } from '../../config';

const MENUS = [
  ['products', 'Sản phẩm'],
  ['customers', 'Khách hàng'],
  ['contact', 'Liên hệ'],
];

function AppBar() {
  const responsive = useSelector(({ settings }) => settings.responsive ?? false);

  const menus = useMemo(() => {
    if (responsive) {
      return (
        <Popover
          content={
            <Menu>
              {MENUS.map(([menu, label]) => (
                <Menu.Item>
                  <ScrollLink to={menu || 'home'} spy smooth>
                    <Heading
                      size={200}
                      fontWeight="bold"
                      textTransform="uppercase"
                      cursor="pointer"
                      marginRight={20}
                    >
                      {label}
                    </Heading>
                  </ScrollLink>
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <IconButton icon={MenuIcon} />
        </Popover>
      );
    }
    return (
      <Pane display="flex">
        {MENUS.map(([menu, label]) => (
          <ScrollLink to={menu || 'home'} spy smooth>
            <Heading
              size={200}
              fontWeight="bold"
              textTransform="uppercase"
              color="white"
              cursor="pointer"
              marginRight={20}
            >
              {label}
            </Heading>
          </ScrollLink>
        ))}
      </Pane>
    );
  }, [responsive]);

  return (
    <Pane display="flex" alignItems="center" height={40} boxSizing="border-box">
      <Pane display="flex" alignItems="center" flexGrow={1}>
        <img style={{ width: 50, height: 'auto' }} src={`${RESOURCES}/xbot.png`} alt="logo" />
        <Heading size={500} marginRight={40} color="white">
          BOT
        </Heading>
      </Pane>
      {menus}
    </Pane>
  );
}

export default AppBar;
