import { Heading, Pane } from 'evergreen-ui';
import React from 'react';
import { useSelector } from 'react-redux';

import Item from './Item';
import serviceSVG from './service.svg';
import softwareSVG from './software.svg';
import solutionSVG from './solution.svg';

const BANNERS = [softwareSVG, solutionSVG, serviceSVG];

function Business() {
  const { business, responsive } = useSelector(({ catalogs, settings }) => ({
    business: catalogs.data?.business ?? [],
    responsive: settings.responsive ?? false,
  }));
  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderTop="1px solid rgba(255,255,255,0.2)"
      padding={30}
    >
      <Heading color="white" size={700}>
        Lĩnh vực hoạt động và kinh doanh
      </Heading>
      <Heading color="white" size={300} marginTop={5}>
        Sản xuất và kinh doanh các giải pháp phần mềm phục vụ quản trị doanh nghiệp và ngành giáo
        dục Việt Nam
      </Heading>
      <Pane display="flex" flexDirection={responsive ? 'column' : 'row'} marginTop={20}>
        {business.map((item, order) => (
          <Item key={order} {...item} banner={BANNERS[order]} />
        ))}
      </Pane>
    </Pane>
  );
}

export default Business;
