import { Heading, Pane } from 'evergreen-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { Element } from 'react-scroll';

import { RESOURCES } from '../../config';
import Stat from './Stat';

function Stats() {
  const { stats, customers, responsive } = useSelector(({ catalogs, settings }) => ({
    stats: catalogs.data?.stats ?? [],
    customers: catalogs.data?.customers ?? [],
    responsive: settings.responsive ?? false,
  }));

  return (
    <Element name="customers">
      <Pane maxWidth={1024} padding={40} margin="auto">
        <Heading size={800} textAlign="center">
          Vì sao chọn XBOT?
        </Heading>
        <Pane
          marginTop={20}
          display="flex"
          flexDirection={responsive ? 'column' : 'row'}
          alignItems="center"
          justifyContent="space-between"
        >
          {stats.map((stat, idx) => (
            <Stat key={idx} {...stat} />
          ))}
        </Pane>
        <Heading size={500} marginTop={20} textAlign="center">
          Khách hàng tiêu biểu
        </Heading>
        <Pane display="inline-block">
          {customers.map((customer, idx) => (
            <Pane key={idx} padding={20} width="fit-content" float="left">
              <img height={50} alt={customer} src={`${RESOURCES}/customers/${customer}`} />
            </Pane>
          ))}
        </Pane>
      </Pane>
    </Element>
  );
}

export default Stats;
