import { Heading, Link, Pane, Text } from 'evergreen-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { Element, Link as ScrollLink } from 'react-scroll';

const MENUS = [
  ['home', 'Giới thiệu'],
  ['products', 'Sản phẩm'],
  ['customers', 'Khách hàng'],
];
function Contact() {
  const { email, hotline, finance, headquarters } = useSelector(
    ({ catalogs }) => catalogs.data?.contact ?? {}
  );
  return (
    <Element name="contact">
      <Pane background="rgba(235,237,240,0.8)" paddingTop={60} paddingBottom={60}>
        <Pane maxWidth={1024} margin="auto" paddingLeft={20} paddingRight={20}>
          <Pane display="flex">
            <Pane display="flex" flexDirection="column" flexGrow={1}>
              <Heading size={600} textTransform="uppercase" marginBottom={10}>
                Liên hệ
              </Heading>
              <Heading size={400} textTransform="uppercase" marginBottom={6}>
                Công ty cổ phần công nghệ XBOT
              </Heading>
              <Pane display="flex" marginBottom={2}>
                <Text width={150}>Email:</Text>
                <Link href={`mailto:${email}`}>{email}</Link>
              </Pane>
              <Pane display="flex" marginBottom={2}>
                <Text width={150}>Hotline:</Text>
                <Link href={`tel:${hotline}`}>{hotline}</Link>
              </Pane>
              <Pane display="flex" marginBottom={2}>
                <Text width={150}>Hành chính - Kế toán:</Text>
                <Link href={`tel:${finance}`}>{finance}</Link>
              </Pane>
              <Pane display="flex" marginBottom={2}>
                <Text width={150}>Trụ sở:</Text>
                <Text fontWeight="bold">{headquarters}</Text>
              </Pane>
            </Pane>
            <Pane>
              <Heading size={600} textTransform="uppercase" marginBottom={10}>
                Công ty
              </Heading>
              {MENUS.map(([menu, label]) => (
                <ScrollLink to={menu || 'home'} spy smooth>
                  <Heading marginTop={10} size={200} cursor="pointer" marginRight={20}>
                    {label}
                  </Heading>
                </ScrollLink>
              ))}
            </Pane>
          </Pane>
          <Heading size={100} textAlign="center" marginTop={30}>
            XBOT © 2018 All Rights Reserved
          </Heading>
        </Pane>
      </Pane>
    </Element>
  );
}

export default Contact;
