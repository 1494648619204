import React from 'react';

import Contact from '../Contact';
import Home from '../Home';
import Products from '../Products';
import Stats from '../Stats';

function SinglePage() {
  return (
    <>
      <Home />
      <Products />
      <Stats />
      <Contact />
    </>
  );
}

export default SinglePage;
