import { combineReducers } from 'redux';

import catalogs from './catalogs';
import settings from './settings';

const rootReducer = combineReducers({
  catalogs,
  settings,
});

export default rootReducer;
