import { Heading, Pane, Paragraph } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

function Item({ banner, name, description }) {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="center"
      width={250}
      padding={10}
      margin={10}
      background="rgba(255,255,255,0.1)"
    >
      <img src={banner} height={130} alt="banner" />
      <Heading color="white" marginTop={10} marginBottom={5}>
        {name}
      </Heading>
      <Paragraph color="white" textAlign="center">
        {description}
      </Paragraph>
    </Pane>
  );
}

Item.propTypes = {
  banner: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Item;
