import { Heading, Pane, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

function Stat({ name, value, description }) {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={10}
      marginBottom={10}
    >
      <Heading size={700} color="rgb(22, 99, 186)">
        {value}
      </Heading>
      <Text marginTop={5} size={300} fontWeight="bold" textTransform="uppercase">
        {name}
      </Text>
      <Text size={300}>{description}</Text>
    </Pane>
  );
}

Stat.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Stat;
