import { ThemeProvider } from 'evergreen-ui';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch as RouterSwitch } from 'react-router-dom';

import { Waiting } from './components';
import { handleGetCatalogs } from './redux/actions/catalogs';
import { handleUpdateResponsive, handleUpdateSettings } from './redux/actions/settings';
import PrivacyPolicy from './screens/PrivacyPolicy';
import SinglePage from './screens/SinglePage';
import { refTheme } from './Themes';

export default function App() {
  const dispatch = useDispatch();
  const handling = useSelector(({ catalogs }) => catalogs.handling ?? false);

  useEffect(() => {
    dispatch(handleGetCatalogs());
    const cached = localStorage.getItem('settings');
    if (cached) {
      const parsed = JSON.parse(cached);
      dispatch(handleUpdateSettings(parsed));
      if (parsed.dark) {
        document.body.style.backgroundColor = '#30404d';
      }
    }

    dispatch(handleUpdateResponsive(window.innerWidth < 700));
    window.addEventListener('resize', () =>
      dispatch(handleUpdateResponsive(window.innerWidth < 700))
    );
  }, []);

  return (
    <ThemeProvider value={refTheme}>
      {handling ? <Waiting /> : null}
      <BrowserRouter>
        <Suspense fallback={<Waiting fullscreen />}>
          <RouterSwitch>
            <Route exact path="/privacy">
              <PrivacyPolicy />
            </Route>
            <Route exact path="/">
              <SinglePage />
            </Route>
          </RouterSwitch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}
