import { toaster } from 'evergreen-ui';
import { all, put, takeLatest } from 'redux-saga/effects';

import { CATALOGS } from './constants';
import metadata from './metadata.json';

function* update(data = {}) {
  yield put({
    type: CATALOGS.update,
    data,
  });
}

function* getCatalogs() {
  try {
    yield* update(metadata);
  } catch ({ message }) {
    toaster.danger(message);
    yield* update();
  }
}

export const handleGetCatalogs = () => ({
  type: CATALOGS.handlers.get,
});

export default function* saga() {
  yield all([yield takeLatest(CATALOGS.handlers.get, getCatalogs)]);
}
