export const CATALOGS = {
  handlers: {
    get: 'CATALOGS_QUERY_HANDLER',
  },
  update: 'CATALOGS_UPDATE',
};

export const SETTINGS = {
  update: 'UPDATE_SETTINGS',
  responsive: 'UPDATE_RESPONSIVE',
};

export const SIGN_OUT = 'SIGN_OUT';
