import { Avatar, Button, Heading, Pane, Paragraph, Popover, Text } from 'evergreen-ui';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { RESOURCES } from '../../config';

const COLORS = [
  ['rgba(91,57,128,0.1)', '#5b3980'],
  ['rgba(62,150,50,0.1)', '#3e9632'],
  ['rgba(52,122,168,0.1)', '#347aa8'],
  ['rgba(189,79,36,0.1)', '#bd4f24'],
];

function Product({ index, id, name, references, description }) {
  const responsive = useSelector(({ settings }) => settings.responsive ?? false);

  const [background, color] = COLORS[index % 4];
  return (
    <Pane display="flex" hoverElevation={3} marginTop={10}>
      <Pane
        background={background}
        padding={10}
        display="flex"
        flexDirection="column"
        minWidth={responsive ? 150 : 250}
        maxWidth={responsive ? 150 : 250}
      >
        <Pane flexGrow={1}>
          <Pane display="flex" alignItems="center">
            <Avatar src={`${RESOURCES}/${id}.png`} name={id} size={40} />
            <Text
              marginLeft={10}
              size={500}
              color={color}
              fontWeight="bold"
              textTransform="uppercase"
            >
              {id}
            </Text>
          </Pane>
          <Heading size={400} marginTop={10} color={color}>
            {name}
          </Heading>
        </Pane>
        <Popover
          content={
            <Pane width={400} padding={20}>
              <Paragraph textAlign="justify">{Parser(references)}</Paragraph>
            </Pane>
          }
        >
          <Button size="small">Cơ sở thực hiện</Button>
        </Popover>
      </Pane>
      <Paragraph paddingLeft={10} paddingRight={10} textAlign="justify">
        {Parser(description)}
      </Paragraph>
    </Pane>
  );
}

Product.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  references: PropTypes.string,
  description: PropTypes.string,
};

Product.defaultProps = {
  references: '',
  description: '',
};

export default Product;
