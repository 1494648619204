import { Button, Heading, Pane, PhoneIcon } from 'evergreen-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { animateScroll, Element } from 'react-scroll';

import { AppBar } from '../../components';
import Business from '../Business';
import homeSVG from './home.svg';

function Home() {
  const responsive = useSelector(({ settings }) => settings.responsive ?? false);

  return (
    <Pane
      backgroundColor="rgb(22, 99, 186)"
      backgroundImage="linear-gradient(-45deg, rgb(22, 99, 186), rgb(14, 52, 156))"
    >
      <Pane maxWidth={1024} margin="auto" padding={20}>
        <AppBar />
        <Element name="home">
          <Pane
            display="flex"
            flexDirection={responsive ? 'column-reverse' : 'row'}
            paddingTop={30}
            paddingBottom={30}
          >
            <Pane display="flex" flexDirection="column" justifyContent="center" marginRight={20}>
              <Heading color="white" size={800}>
                Nền tảng quản trị giáo dục toàn diện
              </Heading>
              <Heading color="white" size={400} marginTop={20} marginBottom={20}>
                Giải pháp hệ thống thông tin quản trị giáo dục được tin dùng bởi +5,000 trường học
                tại Việt Nam
              </Heading>
              <Button
                width={150}
                iconBefore={PhoneIcon}
                onClick={() => animateScroll.scrollToBottom()}
              >
                Liên hệ tư vấn
              </Button>
            </Pane>
            <img src={homeSVG} alt="banner" height={300} />
          </Pane>
          <Business />
        </Element>
      </Pane>
    </Pane>
  );
}

export default Home;
